
import { Mixin } from "@/core/mixins/mixin";
import { Component, Mixins } from "vue-property-decorator";
import {
  Row,
  Button,
  Field,
  NavBar,
  Image as VanImage,
  Icon,
  Step,
  Steps,
  Col,
  List,
  Popup,
  Cell,
  Sticky,
} from "vant";
import { GrowthValues, MemberLevels } from "@/shims.decorator";
import navbar from "@/components/NavBar.vue";

@Component({
  components: {
    Mixin,
    navbar,
    [Row.name]: Row,
    [Col.name]: Col,
    [Button.name]: Button,
    [Field.name]: Field,
    [NavBar.name]: NavBar,
    [VanImage.name]: VanImage,
    [Icon.name]: Icon,
    [Step.name]: Step,
    [Steps.name]: Steps,
    [List.name]: List,
    [Popup.name]: Popup,
    [Cell.name]: Cell,
    [Sticky.name]: Sticky,
  },
})
export default class growValue extends Mixins(Mixin) {
  public growList: Array<GrowthValues> = [];
  public existGrowthValue = 0;
  public list = {
    loading: false,
    finished: false,
  };
  public page = {
    current: 1,
    size: 10,
  };

  mounted(): void {
    this.getLevelDetail(true);
  }

  onClickLeft(): void {
    this.$router.go(-1);
  }

  // 获取当前用户的会员成长值明细
  getLevelDetail(first?: boolean): void {
    if (first) {
      this.page.current = 1;
    } else {
      this.page.current++;
    }
    this.$api.memberApi.cuUser.growthScores(
      { pageNum: this.page.current, pageSize: this.page.size },
      ({ data }) => {
        this.existGrowthValue = data.existGrowthValue!;
        if (this.page.current === 1) {
          this.growList = (data.memberGrowthPagePage! as MemberLevels).records!;
        } else {
          this.growList = this.growList.concat(
            (data.memberGrowthPagePage! as MemberLevels).records!
          );
        }
        this.list.loading = false;
        this.list.finished =
          (data.memberGrowthPagePage! as MemberLevels).current! >=
          (data.memberGrowthPagePage! as MemberLevels).pages!;
      }
    );
  }
  onClickRight(): void {
    let name = "等级指南";
    let type = "GRADE_GUIDE";
    this.$router.push(`/personal-data/notice?name=${name}&type=${type}`);
  }
}
