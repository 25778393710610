
import { Component, Mixins } from "vue-property-decorator";
import { Mixin } from "@/core/mixins/mixin";
import MySwiper from "@/components/MySwiper.vue";
import VipMain from "@/views/Mall/MallPage/VipMain.vue";
import {
  Row,
  Button,
  Field,
  NavBar,
  Image as VanImage,
  Icon,
  Step,
  Steps,
  Col,
  Popup,
} from "vant";
import { monitorEvent } from "@/utils/youMengMonitor";

@Component({
  components: {
    Mixin,
    VipMain,
    MySwiper,
    [Row.name]: Row,
    [Col.name]: Col,
    [Button.name]: Button,
    [Field.name]: Field,
    [NavBar.name]: NavBar,
    [VanImage.name]: VanImage,
    [Icon.name]: Icon,
    [Step.name]: Step,
    [Steps.name]: Steps,
    [Popup.name]: Popup,
  },
})
export default class Membership extends Mixins(Mixin) {
  public type = "coverflow";
  public auto = "auto";
  public active = "";
  public coverflowEffect = {
    rotate: 5,
    stretch: 10,
    depth: 120,
    modifier: 1,
    slideShadows: false,
  };
  public levelInfo: Array<member.Equity> = []; // 权益列表
  public popupcontent = {
    popuptitle: "",
    desc: "",
  };
  public showPopup = false; //会员权益弹窗
  public memberInfoList: Array<member.MemberLevel> = []; //会员卡及权益列表
  public memberLevelName = "";
  growthValue = 0;
  mark = false;

  get mylevel(): number {
    return this.memberInfoList.findIndex((item) => item);
  }

  created(): void {
    this.$toast.loading({
      duration: 0, // 持续展示 toast
      forbidClick: true,
    });
    this.getMemberInfo();
  }

  // 查询当前用户的平台会员卡及会员权益
  getMemberInfo(): void {
    this.$api.memberApi.cuUser.memberEquities((data) => {
      this.active = this.getCurrentLevel(data.data.memberLevelCode!);
      this.growthValue = data.data.growthValue!;
      this.memberInfoList = data.data.memberLevels!;
      this.levelInfo = data.data.memberLevels![0].equities!;
      this.memberLevelName = this.memberInfoList[0].memberLevelName!;
      this.levelInfo = this.memberInfoList[Number(this.active)].equities!;
      this.memberLevelName =
        this.memberInfoList[Number(this.active)].memberLevelName!;
      this.$nextTick(() => {
        this.$toast.clear();
        this.mark = true;
      });
    });
  }
  getCurrentLevel(level: string): string {
    let levels = "";
    switch (level) {
      case "LV0":
        levels = "0";
        break;
      case "LV1":
        levels = "1";
        break;
      case "LV2":
        levels = "2";
        break;
      case "LV3":
        levels = "3";
        break;
      case "LV4":
        levels = "4";
        break;
    }
    return levels;
  }
  onClickLeft(): void {
    this.$router.go(-1);
  }
  onClickRight(): void {
    let name = "等级指南";
    let type = "GRADE_GUIDE";
    monitorEvent("VIPCenter_ClickLevelGuide", "点击等级指南"); // 埋点：会员中心，点击等级指南
    this.$router.push(`/personal-data/notice?name=${name}&type=${type}`);
  }
  showPouop(item: member.Equity): void {
    this.popupcontent.popuptitle = item.name!;
    this.popupcontent.desc = item.content!;
    this.showPopup = true;
  }
  slideChanges(swiper: any): void {
    this.active = swiper.activeIndex;
    this.levelInfo = this.memberInfoList[swiper.activeIndex].equities!;
    this.memberLevelName =
      this.memberInfoList[swiper.activeIndex].memberLevelName!;
  }
  ViewDetail(): void {
    monitorEvent("VIPCenter_ClickGrowthDetail", "点击成长值明细"); // 埋点：会员中心，点击成长值明细
    this.$router.push("/member-records");
  }
}
